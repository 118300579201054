import * as Yup from "yup";

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

export const agentSchema = Yup.object().shape({
  level: Yup.string(),
  username: Yup.string()
    .required("Username is required")
    .min(3, "Username must be at least 3 characters long"),
  password: Yup.string()
    .matches(passwordRules, {
      message:
        " password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special",
    })
    .required("Password is Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  // balance: Yup.number().required("balance is required"),
});

import React from "react";
import { Form, Modal } from "react-bootstrap";
import ButtonWrapper from "../global/button";
// import GenericInputField from "../genericInput/GenericInputField";
// import walletIcon from "../../assets/wallet.svg";
const AgentPopUp = ({
  show,
  handleClose,
  handleFunction,
  userDetail,
  setUserDetail,
  paymentLoading,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetail((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      centered
      className="notification-popup add-payment-popup add-commission-popup deposit-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {userDetail?.type === "deposit" ? "Deposit Money" : "Withdraw Money"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="tabs-section">
          <div
            className={`text-center d-flex justify-content-center ${"mb-4"}`}
          >
            <button
              className={`${
                userDetail.type === "deposit"
                  ? "modal-tab-button modal-active"
                  : "modal-tab-button"
              }`}
              onClick={() => setUserDetail({ ...userDetail, type: "deposit" })}
              // setModalType("deposit")}
            >
              Deposit
            </button>
            <button
              className={`${
                userDetail.type === "withdraw"
                  ? "modal-tab-button modal-active"
                  : "modal-tab-button"
              }`}
              onClick={
                () => setUserDetail({ ...userDetail, type: "withdraw" })
                //   setUserDetail((prev) => ({
                //     ...prev,
                //     type: "deposit",
                //   }))
              }
              // setModalType("withdraw")}
            >
              {" "}
              Withdraw
            </button>
          </div>
          <Form onSubmit={(e) => handleFunction(e)}>
            {/* {userDetail?.creator?.level &&
              userDetail?.creator?.level !== "master" && (
                <div className="row-one d-flex justify-content-end">
                  <Badge
                    bg={`${
                      userDetail?.creator?.balance > 0 ? "success" : "danger"
                    } py-3 px-3 d-flex justify-content-center align-items-end`}
                  >
                    <span className="fw-normal">
                      {userDetail?.creator?.balance || 0}
                    </span>
                    <img
                      src={walletIcon}
                      alt="wallet"
                      className=" wallet-img ms-2"
                    />
                  </Badge>
                </div>
              )} */}
            {userDetail?.creator?.level &&
              userDetail?.creator?.level !== "master" && (
                <Form.Group className="mb-2">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text py-3 pe-1" id="">
                        Current Balance:
                      </span>
                    </div>
                    <input
                      type="number"
                      placeholder={`Enter amount`}
                      autoComplete="off"
                      name="balance"
                      value={userDetail?.creator?.balance + ".00"}
                      isRequired={true}
                      className={`form-control py-3 px-1 modal-input ${
                        userDetail?.creator?.balance > 0
                          ? "text-success"
                          : "text-danger"
                      }`}
                      readOnly
                    />
                  </div>
                </Form.Group>
              )}

            <Form.Group className="mb-3">
              {/* <Form.Label>
                {userDetail?.type === "deposit"
                  ? "Deposit Amount"
                  : "Withdraw Amount"}
              </Form.Label> */}
              {/* <Form.Control
                type="number"
                placeholder={`Enter amount`}
                autoComplete="off"
                name="amount"
                onChange={(e) => handleChange(e)}
                isRequired={true}
              /> */}
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text py-3 pe-1" id="">
                    {userDetail?.type === "deposit"
                      ? "Deposit Amount:"
                      : "Withdraw Amount:"}
                  </span>
                </div>
                <input
                  type="number"
                  placeholder={`Enter amount`}
                  autoComplete="off"
                  name="amount"
                  onChange={(e) => handleChange(e)}
                  isRequired={true}
                  className="form-control py-3 px-1 modal-input"
                />
              </div>
            </Form.Group>
            {/* <GenericInputField
              label={
                userDetail?.type === "deposit"
                  ? "Deposit Amount"
                  : "Withdraw Amount"
              }
              name="amount"
              type={"number"}
              value={userDetail?.amount}
              handleChange={handleChange}
            /> */}
            <div className="deposit-popup-btn">
              <ButtonWrapper
                text="Save"
                type="submit"
                loading={paymentLoading}
              />
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AgentPopUp;

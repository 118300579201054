import React from "react";
import { Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import ButtonWrapper from "../global/button";
import { agentSchema } from "./AgentValidationSchema";
import { useMyContect } from "../../context/myContext";
import GenericInputField from "../genericInput/GenericInputField";

const AddAgentPopUp = ({ show, loading, handleClose, handleNewAgent }) => {
  const { user } = useMyContect();

  const getLevel = () => {
    let level;
    if (user?.level === "master") {
      level = "distributor";
    } else if (user?.level === "distributor") {
      level = "sub-distributor";
    } else if (user?.level === "sub-distributor") {
      level = "store";
    } else {
      level = "cashier";
    }

    const displayLevel = level.charAt(0).toUpperCase() + level.slice(1);
    return { displayLevel, apiLevel: level };
  };
  const { displayLevel, apiLevel } = getLevel();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      centered
      className="notification-popup add-payment-popup add-commission-popup deposit-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Agent</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={{
            level: apiLevel,
            username: "",
            password: "",
            confirmPassword: "",
            // balance: "",
          }}
          validationSchema={agentSchema}
          onSubmit={(values) => {
            handleNewAgent(values);
          }}
        >
          {({ handleSubmit, handleChange, values, errors, touched }) => (
            <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label className="level-label">
                  <span>Level:</span>{" "}
                  <p class="badge badge-pill badge-success p-2 m-0">
                    {displayLevel}
                  </p>
                </Form.Label>
                {/* <Form.Select
                  name="level" 
                  value={values.level}
                  onChange={handleChange}
                  isInvalid={touched.level && !!errors.level}
                >
                  <option value="distributor">Distributor</option>
                  <option value="sub-distributor">Sub Distributor</option>
                  <option value="store">Store</option>
                  <option value="master">Master</option>
                  <option value="cashier">Cashier</option>
                </Form.Select>
                {console.log(errors, Math.floor(Math.random() * 90000) + 10000)}
                <Form.Control.Feedback type="invalid">
                  {errors.level}
                </Form.Control.Feedback> */}
              </Form.Group>
              <Form.Group>
                {/* <Form.Control
                  type="text"
                  placeholder={`username`}
                  name="username"
                  value={values.username}
                  onChange={handleChange}
                  isInvalid={touched.username && !!errors.username}
                /> */}
                <GenericInputField
                  label={"User Name"}
                  name="username"
                  type={"text"}
                  value={values.username}
                  handleChange={handleChange}
                  isInvalid={touched.username && !!errors.username}
                />
                {console.log(errors)}
                <Form.Control.Feedback
                  type="invalid"
                  className={`${touched.username && "d-block"}`}
                >
                  {errors.username}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                {/* <Form.Control
                  type="password"
                  placeholder={`Password`}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  isInvalid={touched.password && !!errors.password}
                /> */}
                <GenericInputField
                  label={"Password"}
                  name="password"
                  type={"password"}
                  value={values.password}
                  handleChange={handleChange}
                  isInvalid={touched.password && !!errors.password}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className={`${touched.password && "d-block"}`}
                >
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                {/* <Form.Control
                  type="password"
                  placeholder={`Confirm Password`}
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  isInvalid={
                    touched.confirmPassword && !!errors.confirmPassword
                  }
                /> */}
                <GenericInputField
                  label={"Confirm Password"}
                  name="confirmPassword"
                  type={"password"}
                  value={values.confirmPassword}
                  handleChange={handleChange}
                  isInvalid={
                    touched.confirmPassword && !!errors.confirmPassword
                  }
                />
                <Form.Control.Feedback
                  type="invalid"
                  className={`${touched.confirmPassword && "d-block"}`}
                >
                  {errors.confirmPassword}
                </Form.Control.Feedback>
              </Form.Group>

              {/* <Form.Group>
                <Form.Label>Balance</Form.Label>
                <Form.Control
                  type="number"
                  placeholder={`Balance`}
                  name="balance"
                  value={values.balance}
                  onChange={handleChange}
                  isInvalid={touched.balance && !!errors.balance}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.balance}
                </Form.Control.Feedback>
              </Form.Group> */}

              {/* <Form.Group>
                <Form.Label>Agent ID (5 digits)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder={`Agent ID`}
                  name="agentId"
                  value={values.agentId}
                  onChange={handleChange}
                  isInvalid={touched.agentId && !!errors.agentId}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.agentId}
                </Form.Control.Feedback>
              </Form.Group> */}

              <div className="deposit-popup-btn">
                <ButtonWrapper loading={loading} text="Create" type="submit" />
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddAgentPopUp;

import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";

const GenericInputField = ({
  label,
  name,
  value,
  type,
  handleChange,
  isInvalid,
}) => {
  return (
    <FloatingLabel controlId="floatingInput" label={label}>
      <Form.Control
        type={type ? type : "text"}
        placeholder=""
        autoComplete="off"
        name={name}
        value={value}
        onChange={handleChange}
        isInvalid={isInvalid}
      />
    </FloatingLabel>
  );
};

export default GenericInputField;

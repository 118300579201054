// import GlobalInput from "../global/input";
import {
  //  useEffect,
  useState,
} from "react";
import "./header.css";
import LanguageDropdown from "./languageDropdown";
import DepositWithdraw from "./depositWithraw";
import { ExitToApp } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ProfileDropdown from "./profileDropdown";
import { useMyContect } from "../../context/myContext";
import {
  // generateDomainToken,
  logoutUser,
} from "../../utils/Apis";
import toast from "react-hot-toast";
// import { getAllDomainName } from "../../utils/Apis";
const Header = ({ toggle, setToggle, title, screenWidth }) => {
  const {
    user,
    // brand,
    // setBrand, setIsLoading
  } = useMyContect();
  // const [display, setDisplay] = useState(false);
  // const [domainName, setDomainName] = useState([]);
  // const [select, setSelect] = useState(null);

  // const handleShow = () => {
  //   setDisplay(!display);
  // };

  const [profileDropdown, setProfileDropdown] = useState(false);
  const profileShow = () => {
    setProfileDropdown(!profileDropdown);
  };

  const [flag, setFlag] = useState(false);
  const handleFlag = () => {
    setFlag(!flag);
  };
  // logout api call here=================
  const handleLogout = async () => {
    const refreshToken = localStorage.getItem("refToken") || "";
    const { status } = await logoutUser({
      refreshToken: refreshToken,
    });

    if (status === 200) {
      toast.success("Logout Successfully");
      localStorage.clear();
      window.location.href = "/";
    }
  };

  // const getDomainName = async () => {
  //   const response = await getAllDomainName();
  //   if (response?.data?.status === 200) {
  //     setDomainName(response?.data?.domianData);
  //   }
  // };
  // useEffect(() => {
  //   // getDomainName();
  //   console.log(localStorage.getItem("brand"));
  //   setSelect(localStorage.getItem("brand"));
  // }, [brand]);

  // For the select deualt value first in the dropdown
  // useEffect(() => {
  //   setSelect(brand);
  // }, [brand]);

  // const handleDropdownClick = async (value) => {
  //   setSelect(value);
  //   setBrand(value);
  //   localStorage.setItem("brand", value);
  //   setIsLoading(true);
  //   const response = await generateDomainToken(value);
  //   setIsLoading(false);
  //   if (response?.status === 200) {
  //     localStorage.setItem("secretDomainKey", response.tokenValue);
  //   }
  // };
  return (
    <div className="header">
      <div className="dashboard-icon">
        <h6 onClick={() => setToggle(!toggle)}>
          {screenWidth <= 1024 && toggle ? (
            <MenuIcon />
          ) : (
            <div className={`${toggle ? "rotateToggle" : ""}`}>
              <ExitToApp />
            </div>
          )}{" "}
          <span>{title}</span>
        </h6>
      </div>
      {/* {user?.role !== "superAdmin" && ( */}
      {/* <div className="search-area header-select" style={{ zIndex: 10 }}>
        <GlobalInput placeholder={"Search..."} value={select} type={"text"} />
        <i className="las la-angle-down"></i>
        <div className="select">
          <div className="toggle" onClick={handleShow}>
            {display ? "Show Inactive" : "Hide Inactive"}
          </div>
          <div className="option" value="" style={{ display: "none" }}>
            <span text_key="ALL_BRANDS" title="Group: USERS, Key: ALL_BRANDS">
              All Brands
            </span>
          </div>
          {domainName?.map((el, i) => (
            <div
              key={i}
              className={`option ${display === false ? " " : "inactive"}`}
              value="81"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onClick={() => handleDropdownClick(el.allDomain)}
            >
              <span>{el?.allDomain}</span>
              <span
                onClick={() => {
                  setDisplay(true);
                }}
              >
                <VisibilityOffIcon />
              </span>
            </div>
          ))}
        </div>
      </div> */}
      {/* )} */}
      <div className="right-header-area">
        <DepositWithdraw />
        <ProfileDropdown
          profileDropdown={profileDropdown}
          handleprofileShow={profileShow}
          user={user}
          handleLogout={handleLogout}
        />
        <LanguageDropdown flagShow={flag} handleFlag={handleFlag} />
      </div>
    </div>
  );
};
export default Header;

import ExportExcel from "../global/exportExcel";
import SelectInput from "../global/selectInput";
import TableColumns from "../global/tableColumns";
import GlobalTable from "../global/globalTable";
import { useEffect, useState } from "react";
import {
  getPlayerData,
  // makeManualDepositWithdraw,
  userPayment,
} from "../../utils/Apis";
import Pagination from "../../utils/Pagination";
import { useMyContect } from "../../context/myContext";
// import DepositPopup from "./deposit";
import WithdrawPopup from "./withdraw";
import toast from "react-hot-toast";

const UserTable = ({ recall, filter }) => {
  const { isLoading, user, setUser } = useMyContect();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages] = useState("");
  const [pageSize, setPageSize] = useState("20");
  const [withdrawShow, setWithdrawShow] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [userDetail, setUserDetail] = useState({
    userId: "",
    amount: 0,
    type: "deposit",
  });

  let options = [
    {
      id: 1,
      value: 20,
    },
    {
      id: 1,
      value: 50,
    },
    {
      id: 1,
      value: 100,
    },
    {
      id: 1,
      value: 500,
    },
  ];
  const [rowData, setRowData] = useState([]);
  const getAllPlayerData = async (page, pageSize) => {
    try {
      const token = localStorage.getItem("accToken");
      if (token) {
        const config = {
          headers: {
            DomainToken: `${token}`,
          },
          params: {
            page: page,
            pageSize: pageSize,
          },
          filter: filter,
        };

        setLoading(true);
        const res = await getPlayerData(config);
        setLoading(false);
        setRowData(res?.data);
        // if (res) {
        //   console.log("res", res.data);
        //   const transformData = res.data?.map((item) => ({
        //     ...item,
        //     id: parseInt(item?.id),
        //     iskycCompleted: item?.iskycCompleted ? "Verified" : "Not Verified",
        //     // brand: item?.brand ? item?.brand : "-",
        //     firstName: item?.firstName ? item?.firstName : "-",
        //     lastName: item?.lastName ? item?.lastName : "-",
        //     mobile: item?.mobile ? item?.mobile : "-",
        //     pin: item?.pin ? item?.pin : "-",
        //     wallet: item?.wallet ? item?.wallet : "-",
        //     USD: item?.Wallets?.[0]?.amount
        //       ? `${parseFloat(item?.Wallets?.[0]?.amount).toFixed(2)} USD`
        //       : "0 USD",
        //     dob: item?.dob ? item?.dob : "-",
        //     country: item?.country ? item?.country : "-",
        //     parentUser: item?.parentUser ? item?.parentUser : "-",
        //     regDate: item?.regDate ? item?.regDate : "-",
        //     lastLoginIP: item?.LoginLogs?.[0]?.ip_address
        //       ? item?.LoginLogs?.[0]?.ip_address
        //       : "-",
        //     lastLogin: item?.LoginLogs?.[0]?.created_at
        //       ? item?.LoginLogs?.[0]?.created_at
        //       : "-",
        //   }));

        //   setRowData(transformData);
        //   setPages(res?.data?.pages);
        // }
      } else {
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error found while fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllPlayerData(page, pageSize);
    // eslint-disable-next-line
  }, [pageSize, page, recall, isLoading]);
  // const [showDeposit, setShowDeposit] = useState(false);

  const actionCellRenderer = (params) => {
    return (
      <div className="users-action-buttons">
        <button
          onClick={() => {
            setUserDetail({
              userId: params?.data?.id,
              amount: params?.data?.amount,
              type: "deposit",
            });
            setWithdrawShow(!withdrawShow);
          }}
          className="deposit-button"
        >
          +
        </button>
        <button
          onClick={() => {
            setUserDetail({
              userId: params?.data?.id,
              amount: params?.data?.amount,
              type: "withdraw",
            });
            setWithdrawShow(!withdrawShow);
          }}
          className="withdraw-button"
        >
          -
        </button>
      </div>
    );
  };
  const [columnDefs, setColumnDefs] = useState([
    // {
    //   headerName: "Brand",
    //   field: "brand",
    //   minWidth: 120,
    //   flex: 1,
    //   cellStyle: { color: "grey", left: "15px" },
    // },
    {
      headerName: "User ID",
      field: "id",
      minWidth: 180,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "asc",
      enableRowGroup: true,
      enablePivot: true,
      cellRenderer: (params) => params.value || "-",
    },
    {
      headerName: "User",
      field: "userName",
      minWidth: 200,
      flex: 1,
      cellRenderer: (params) => params.value || "-",
    },
    {
      headerName: "Agent ID",
      field: "agentId",
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => params.value || "-",
    },
    {
      headerName: "Role",
      field: "role",
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => params.value || "-",
    },
    {
      headerName: "First Name",
      field: "firstName",
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => params.value || "-",
    },
    {
      headerName: "Last Name",
      field: "lastName",
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => params.value || "-",
    },

    // {
    //   headerName: "Email",
    //   field: "email",
    //   minWidth: 280,
    //   flex: 1,
    //   cellRenderer: (params) => params.value || "-",
    // },
    {
      headerName: "Mobile",
      field: "mobile",
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => params.value || "-",
    },
    {
      headerName: "Pin",
      field: "pin",
      minWidth: 100,
      flex: 1,
      cellRenderer: (params) => params.value || "-",
    },
    // {
    //   headerName: 'Balance',
    //   field: 'wallet',
    //   width: '120px',
    //   cellStyle: { 'text-align': 'center' },
    // },
    {
      headerName: "Balance",
      field: "balance",
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => params.value || "-",
    },
    {
      headerName: "KYC Status",
      field: "iskycCompleted",
      minWidth: 120,
      flex: 1,
      cellRenderer: (params) => (params.value ? "True" : "False"),
      cellStyle: (params) => ({
        color: params.value ? "green" : "red",
        textAlign: "center",
      }),
    },
    {
      headerName: "Reg Date",
      field: "regDate",
      minWidth: 150,
      flex: 1,
      sortable: true,
      sortingOrder: ["asc", "desc"],
      sort: "desc",
      cellRenderer: (params) => params.value || "-",
    },
    {
      headerName: "Birthdate",
      field: "dob",
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => params.value || "-",
    },
    {
      headerName: "Country",
      field: "country",
      minWidth: 150,
      flex: 1,
      cellRenderer: (params) => params.value || "-",
    },
    // {
    //   headerName: "Parent User Name",
    //   field: "creator",
    //   minWidth: 250,
    //   flex: 1,
    //   sortable: true,
    //   sortingOrder: ["asc", "desc"],
    //   sort: "desc",
    //   cellRenderer: (params) => params?.username || "-",
    // },
    // {
    //   headerName: "Last Login",
    //   field: "lastLogin",
    //   minWidth: 150,
    //   flex: 1,
    //   sortable: true,
    //   sortingOrder: ["asc", "desc"],
    //   sort: "desc",
    //   cellRenderer: (params) => params.value?.replace("T", " "),
    // },
    // {
    //   headerName: "Last Login Ip Address",
    //   field: "lastLoginIP",
    //   minWidth: 250,
    //   flex: 1,
    //   cellRenderer: (params) => params.value?.replace("T", " "),
    // },
    {
      headerName: "Actions",
      flex: 1,
      minWidth: 250,
      field: "actionButton",
      cellRenderer: (item) => actionCellRenderer(item),
    },
  ]);
  const [tableData, setTableData] = useState(
    columnDefs.map((item) => ({
      ...item,
      checked: true,
    }))
  );

  const checkedBox = (e) => {
    const updatedTableData = tableData.map((item) =>
      item.field === e.target.name ? { ...item, checked: !item.checked } : item
    );

    setTableData(updatedTableData);
    const updatedColumn = updatedTableData.filter((item) => item.checked);
    setColumnDefs(updatedColumn);
  };
  const handleDepositWithdraw = async (e) => {
    try {
      e.preventDefault();
      if (
        userDetail?.amount <= user?.balance ||
        user?.level === "master" ||
        userDetail?.type === "withdraw"
      ) {
        if (userDetail?.userId && userDetail?.type && userDetail?.amount) {
          setPaymentLoading(true);
          const res = await userPayment(userDetail);
          setPaymentLoading(false);
          console.log(res);
          const { status } = res || {};
          if (status !== 200) {
            return toast.error("Failed");
          }
          if (userDetail.type === "deposit" && user?.level !== "master") {
            setUser({ ...user, balance: user.balance - userDetail.amount });
          }
          setWithdrawShow(false);
          getAllPlayerData(page, pageSize);
          toast.success(
            `Your ${userDetail.type} request successfully completed`
          );
          setUserDetail({
            userId: "",
            amount: 0,
            type: "",
          });
        } else {
          setPaymentLoading(false);
          return toast.error("Please enter the amount or please try again!");
        }
      } else {
        setPaymentLoading(false);
        return toast.error("Please Enter a valid amount");
      }
    } catch (err) {
      setPaymentLoading(false);
      return toast.error("Something went wrong!");
    }
  };

  return (
    <div className="deposit-table">
      <div className="top-area">
        <h6>Users</h6>
        <div className="right-area">
          <ExportExcel rowData={rowData} tableData={tableData} />
          <SelectInput
            listArray={options}
            defaultOption="20"
            handleSelectOption={(e) => {
              setPageSize(e.target.value ? e.target.value : "20");
            }}
          />

          <TableColumns tableData={tableData} checkedBox={checkedBox} />
        </div>
      </div>
      {isLoading || loading ? (
        <div className="spinner my-5"></div>
      ) : rowData?.length === 0 ? (
        <h4 className="text-center my-5">No Data Found</h4>
      ) : (
        <>
          <GlobalTable columnDefs={columnDefs} rowData={rowData} />
          <Pagination
            currentPage={page}
            totalPages={pages}
            onPageChange={setPage}
          />
        </>
      )}
      {/* <DepositPopup
        show={showDeposit}
        handleClose={() => setShowDeposit(false)}
        handleFunction={handleDepositWithdraw}
        userDetail={userDetail}
        setUserDetail={setUserDetail}
        paymentLoading={paymentLoading}
      /> */}
      <WithdrawPopup
        show={withdrawShow}
        handleClose={() => setWithdrawShow(false)}
        handleFunction={handleDepositWithdraw}
        userDetail={userDetail}
        setUserDetail={setUserDetail}
        paymentLoading={paymentLoading}
      />
    </div>
  );
};
export default UserTable;
